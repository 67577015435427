<template>
  <div class="container">
    <p class="error">У вас нет прав к этой странице</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.error {
  font-size: 4rem;
  text-align: center;
}
</style>
